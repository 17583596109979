'use strict';

var swiper = require('swiper/bundle');
var Swiper = swiper.Swiper;
const { toasterMessageDisplay } = require('app_conairllc_sfra_browse/utilities');

var rdpBottomSwiper = new Swiper('.js-rdp-bottom-swiper', {
    loop: true,
    spaceBetween: 16,
    slidesPerView: 5
});

var rdpTopSwiper = new Swiper('.js-rdp-top-swiper', {
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    thumbs: {
        swiper: rdpBottomSwiper
    },
    on: {
        slideChange: function (swiper) {
            var currentSlide = $('.js-rdp-top-swiper .swiper-slide')[swiper.previousIndex];
            var youtubePlayer = $(currentSlide).find('iframe').get(0);
            if (youtubePlayer) {
                youtubePlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            }
        }
    }
});

function initRecipeBottomSwiper(slidesPerViewInLargeScreen = 3.5) {
    var rdpSimilarRecipeBottomSwiper = new Swiper('.js-rpd-bottom-similar-swiper', {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 16,
        pagination: {
            el: '.swiper-custom-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-next-btn',
            prevEl: '.swiper-prev-btn'
        },
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 16
            },
            768: {
                slidesPerView: slidesPerViewInLargeScreen,
                spaceBetween: 16
            },
            1024: {
                slidesPerView: slidesPerViewInLargeScreen,
                spaceBetween: 16
            }
        }
    });
}

module.exports = {
    printRecipe: function () {
        $('body').on('click', '.printButton', function () {
            window.print();
        });
    },
    copyRecipeLink: function () {
        $('body').on('click', '#fa-link', function (event) {
            event.preventDefault();
            var $temp = $('<input>');
            $('body').append($temp);
            $temp.val($('#shareUrl').val()).select(); // Select the value of the temporary input element
            $temp[0].setSelectionRange(0, 99999); // For mobile devices
            document.execCommand('copy');
            $temp.remove();
            toasterMessageDisplay('success', $('#copySuccessMessage').val());
        });
    },
    viewRecipeLink: function () {
        // view recipe link for mobile
        $('.js-view-recipe').on('click', function () {
            var $headerHeight = $('.header').height() + 48;
            $('html,body').animate(
                { scrollTop: $('#ingredients-section').offset().top - $headerHeight },
                'slow'
            );
        });
    },
    initRecipeBottomSwiper: initRecipeBottomSwiper
};
