'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $(element).closest('.main-menu').removeClass('overflow-hidden');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

function setHeaderOffsetToElement(element) {
    if ($('.header').hasClass('header-fixed')) {
        $(element).css('top', $('.header').outerHeight());
    } else {
        $(element).css('top', $('.header-banner').outerHeight() + $('.header').outerHeight());
    }
}

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility(
        '.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            e.preventDefault();
            if (!isDesktop(this)) {
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                var closeMenu = $('<li class="nav-menu"></li>');
                if ($(this).parent().children('.dropdown-menu').find('.top-category').length === 0) {
                    li.append(link);
                    closeMenu.append($('.close-menu').first().clone());
                    $(this).parent().children('.dropdown-menu')
                        .prepend(li)
                        .prepend(closeMenu)
                        .attr('aria-hidden', 'false');
                }
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                if ($(this).parent().hasClass('show')) {
                    $(this).closest('.main-menu').addClass('overflow-hidden');
                } else {
                    $(this).closest('.main-menu').removeClass('overflow-hidden');
                }
            }
        });

    // to prevent parent dropdown-menu to be close
    $('.main-menu .dropdown .dropdown-menu').on('click', '.dropdown-item, .shopall-link', function (e) {
        if (!isDesktop(this)) {
            e.stopPropagation();
        }
    });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav .dropdown-menu').on('click', '.back', function (e) {
        e.preventDefault();
        $(this).closest('.dropdown-menu').removeClass('show');
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.navbar-nav').find('.dropdown-toggle').attr('aria-expanded', 'false');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');
        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
        setHeaderOffsetToElement('.main-menu');
        if ($('.main-menu').hasClass('in')) {
            $('html, body').addClass('overflow-hidden');
        } else {
            $('html, body').removeClass('overflow-hidden');
        }
        $('.user-profile-flyout').removeClass('in');
        $('.search-form').removeClass('open');
    });

    $('.user-profile-flyout').on('click', '.js-user-profile-toggle', function (e) {
        e.preventDefault();
        var $userProfileFlyout = $(this).closest('.user-profile-flyout');
        $userProfileFlyout.addClass('in');
        if ($userProfileFlyout.hasClass('in')) {
            $('html, body').addClass('overflow-hidden');
        }
        setHeaderOffsetToElement('.user-profile-flyout-body');
        $('.search-form').removeClass('open');
    });

    $('.user-profile-flyout').on('click', '.btn-back', function () {
        $(this).closest('.user-profile-flyout').removeClass('in');
        $('html, body').removeClass('overflow-hidden');
    });

    $('.user-account-dropdown').hover(function () {
        $(this).find('.popover').addClass('show');
        $(this).find('.popover').attr('aria-expanded', 'true');
    }, function () {
        $(this).find('.popover').removeClass('show');
        $(this).find('.popover').attr('aria-expanded', 'false');
    });

    keyboardAccessibility(
        '.navbar-header .user',
        {
            40: function ($popover) { // down
                var focusableEls = $popover.find('a[href]:not([disabled])');
                var firstFocusableEl = focusableEls[0];
                var lastFocusableEl = focusableEls[focusableEls.length - 1];
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                } else {
                    var currentIndex = Array.prototype.indexOf.call(focusableEls, document.activeElement);
                    var nextIndex = currentIndex + 1;
                    focusableEls[nextIndex].focus();
                }
            },
            38: function ($popover) { // up
                var focusableEls = $popover.find('a[href]:not([disabled])');
                var firstFocusableEl = focusableEls[0];
                var lastFocusableEl = focusableEls[focusableEls.length - 1];
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                } else {
                    var currentIndex = Array.prototype.indexOf.call(focusableEls, document.activeElement);
                    var nextIndex = currentIndex - 1;
                    focusableEls[nextIndex].focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.navbar-header .user .popover').attr('aria-expanded', 'false');
                $('.navbar-header .user').focus();
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.navbar-header .user .popover').attr('aria-expanded', 'false');
            },
            13: function () { // enter
                $('.navbar-header .user .popover').addClass('show');
                $('.navbar-header .user .popover').attr('aria-expanded', 'true');
                $('.navbar-header .user li').children('a').first().focus();
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('body').on('click', '#myaccount', function (event) {
        event.preventDefault();
    });

    $(window).on('scroll', function () {
        if ($(window).width() < 992) {
            setHeaderOffsetToElement('.user-profile-flyout-body');
        }
    });

    var isElementFocused = false;
    $(document).on('keydown', function (event) {
        if ((event.key === 'Tab' || event.keyCode === 9) && !isElementFocused) {
            event.preventDefault(); // Prevent the default tabbing behavior
            // set focus on the first menu item except PLP
            if ($('.plp-page').length) {
                $('.product-grid > div:first-child .product-card a').first().focus();
            } else if ($('#checkout-main').length) {
                $('.header-checkout .navbar-header-checkout > a').focus();
            } else {
                $('.main-menu .navbar-nav > .nav-item:first-child > a').focus();
            }
            isElementFocused = true;
        }
    });
};
