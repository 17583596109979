'use strict';

var swiper = require('swiper/bundle');
var Swiper = swiper.Swiper;

var pdpBottomSwiper = new Swiper('.js-pdp-bottom-swiper', {
    loop: true,
    spaceBetween: 16,
    slidesPerView: 5
});
var pdpTopSwiper = new Swiper('.js-pdp-top-swiper', {
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    thumbs: {
        swiper: pdpBottomSwiper
    },
    on: {
        slideChange: function (swiper) {
            var currentSlide = $('.js-pdp-top-swiper .swiper-slide')[swiper.previousIndex];
            var youtubePlayer = $(currentSlide).find('iframe').get(0);
            if (youtubePlayer) {
                youtubePlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            }
        }
    }
});
