'use strict';

/**
 * Adjusts the quantity value of an input field based on the specified operation.
 * The quantity can be incremented or decremented within a specified range.
 *
 * @param {Event} e - The event object associated with the triggering event.
 * @param {string} operation - The operation to perform: 'increment' or 'decrement'.
 */
function qtyAdjust(e, operation) {
    e.preventDefault();
    var fieldName = $(e.target).data('field');
    var parent = $(e.target).closest('.qty-selector');
    var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);
    var maxtValAllowed = parseInt(parent.find('.quantity-field').attr('max'), 10) || 5; // Max 5 only allow as per exsiting website

    if (!isNaN(currentVal)) {
        if (operation === 'increment' && currentVal < maxtValAllowed) {
            parent.find('input[name=' + fieldName + ']').val(currentVal + 1).trigger('change');
        } else if (operation === 'decrement' && currentVal > 1) {
            parent.find('input[name=' + fieldName + ']').val(currentVal - 1).trigger('change');
        }
    } else {
        parent.find('input[name=' + fieldName + ']').val(1).trigger('change');
    }
}

$('body').on('click', '.qty-selector .button-plus ', function (e) {
    qtyAdjust(e, 'increment');
});
$('body').on('click', '.qty-selector .button-minus', function (e) {
    qtyAdjust(e, 'decrement');
});

// Trigger the file input when clicking on the SVG icon
$('.icon-share').on('click', function() {
    $('#attachment').click();
});

$('.form-control-file').on('change', function () {
    const allowedExtensions = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    var file = this.files.length ? this.files[0] : null;

    var $feedback = $(this).closest('.form-group').find('.invalid-feedback');
    var $fileLabel = $(this).siblings('.file-label-custom');

    if (file && allowedExtensions.indexOf(file.type) === -1) {
        $feedback.text('Please upload a valid file. Supported formats are: PNG, JPG, JPEG, and PDF.').show();
        $fileLabel.html('No File Chosen');
        $(this).val('');
    } else if (file) {
        $feedback.hide();
        $fileLabel.addClass('selected').html(file.name);
    }
});

$('#attachment').on('change', function () {
    const allowedExtensions = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
    var file = this.files.length ? this.files[0] : null;

    var $feedback = $(this).closest('.form-group').find('.invalid-feedback');
    var $fileName = $('.file-name');

    if (file && allowedExtensions.indexOf(file.type) === -1) {
        $feedback.text('Please upload a valid file. Supported formats are: PNG, JPG, JPEG, and PDF.').show();
        $fileName.text('No File Chosen');
        $('#attachment').val('');
    } else if (file) {
        $feedback.hide();
        $fileName.text(file.name);
    }
});

$('body').on('click', '.ca-dropdown .dropdown-item, .user-profile-flyout .dropdown-item .dropdown-link', function (event) {
    if (!$(event.target).attr('href')) {
        event.preventDefault();
    }
    var selectedItem = $(this).text();
    var selectedValue = $(this).data('val') ? $(this).data('val') : $(this).text();
    var parent = $(this).parents('.ca-dropdown');
    parent.find('.selected-text').text(selectedItem);
    parent.find('.selected-text-hidden').val(selectedValue);
    if (selectedItem) {
        localStorage.setItem('selectedAccountLinkText', selectedItem);
    }
    parent.addClass('filled');
    if (parent.find('.dropdown-menu.show').length) {
        parent.find('.dropdown-menu.show').removeClass('show');
        parent.removeClass('show');
    }
});

$('body').on('click', '.js-back-to-myaccount', function () {
    var $selectedText = $('.account-left-navigation .ca-dropdown .selected-text');
    var currentPageName = $selectedText.length && $selectedText.data('current-page');
    if (currentPageName) {
        localStorage.setItem('selectedAccountLinkText', currentPageName);
    }
});

$('.js-retail-sales-form').on('change', '.dropdown-business-type', function () {
    if ($(this).val() === 'Other') {
        $(this).closest('form').find('.other-option').removeClass('d-none');
    } else {
        $(this).closest('form').find('.other-option').addClass('d-none');
    }
});

$(function () {
    var selectedAccountLinkText = localStorage.getItem('selectedAccountLinkText');
    if (selectedAccountLinkText) {
        $('.selectedAccount-linkText').text(selectedAccountLinkText);
    }
});
