'use strict';

var $modalBox;
var $modalDialog;
var $modalContent;
var $modalHeader;
var $modalbody;

var modalBuilder = function (modalId, labelledBy, modalTitle) {
    $modalBox = $('<div>');
    $modalBox.addClass('modal fade');
    $modalBox.attr('id', modalId);
    $modalBox.attr('tabindex', '-1');
    $modalBox.attr('role', 'dialog');
    $modalBox.attr('aria-labelledby', labelledBy);
    $modalBox.attr('aria-hidden', 'true');
    $modalBox.attr('aria-modal', 'true');

    $modalDialog = $('<div>');
    $modalDialog.addClass('modal-dialog modal-dialog-centered');
    $modalDialog.attr('role', 'document');

    $modalContent = $('<div>');
    $modalContent.addClass('modal-content');

    $modalHeader = $('<div>');
    $modalHeader.addClass('modal-header');

    var $closeButton = $('<button>');
    $closeButton.attr('type', 'button');
    $closeButton.attr('data-dismiss', 'modal');
    $closeButton.attr('aria-label', 'close modal');
    $closeButton.addClass('btn-dismiss-modal icon bg-svg-icon bg-icon-controlDismiss');

    if (modalTitle) {
        var $headerTitle = $('<h2>');
        $headerTitle.attr('id', labelledBy);
        $headerTitle.addClass('modal-title title-m-bold');
        $modalHeader.append($headerTitle);
        $modalHeader.append($closeButton);
        $modalContent.append($modalHeader);
    } else {
        $modalContent.append($closeButton);
    }

    $modalbody = $('<div>');
    $modalbody.addClass('modal-body');

    $modalContent.append($modalbody);
    $modalDialog.append($modalContent);

    $modalBox.append($modalDialog);

    return $modalBox;
};

/**
 * Fetches content via an AJAX GET request and displays it in a dynamically created modal.
 *
 * @param {string} url - The URL from which to fetch the content.
 * @param {string} modalId - The ID to assign to the dynamically created modal.
 * @param {Function} successCallback - The callback function to execute upon a successful AJAX request.
 * @param {Function} errorCallback - The callback function to execute if the AJAX request fails.
 */
function showModalWithAjax(url, modalId, successCallback, errorCallback) {
    var $modalBlock = modalBuilder(modalId, '', '');
    $('body').append($modalBlock);
    $.ajax({
        url: url,
        type: 'GET',
        success: function (response) {
            // Append the response to the modal body
            $('#' + modalId).find('.modal-body').html(response);
            // Call the success callback function
            successCallback();
        },
        error: function (error) {
            // Call the error callback function
            errorCallback(error);
        }
    });
}

module.exports = {
    modalBuilder: modalBuilder,
    showModalWithAjax: showModalWithAjax
};

