'use strict';

var focusHelper = require('base/components/focus');
const { find } = require('lodash');

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    var trackingConsentData = $('.tracking-consent');
    if (!trackingConsentData.data('caonline')) {
        return;
    }

    var urlContent = trackingConsentData.data('url');
    var urlAccept = trackingConsentData.data('accept'); // This is the URL you need for the "Go" button
    var tokenName = trackingConsentData.data('tokenname');
    var token = trackingConsentData.data('token');

    var htmlString = '<!-- Modal -->'
        + '<div class="modal confirmation-modal show custom-backdrop" id="consent-tracking" aria-modal="true" role="dialog" style="display: block;" data-backdrop="static" data-keyboard="false">'
        + '<div class="modal-dialog modal-dialog-centered">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-body ca-p-24 text-center" style="min-height: 312px;"></div>'
        + '</div>'
        + '<input type="hidden" class="hidden-url" data-url="' + urlAccept + '" />'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('#consent-tracking .modal-body').spinner().start();

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('#consent-tracking .modal-body').html(response);
            $('#consent-tracking').modal('show');
            $('#consent-tracking .modal-body').spinner().stop();

            // Bind click event to the button inside .button-wrapper
            $('body').on('click', '#consent-tracking .button-wrapper button', function(e) {
                e.preventDefault();
                var url = $(this).closest('.modal').find('.hidden-url').data('url');
                var data = {};
                data[tokenName] = token;
                $.ajax({
                    url: url,
                    type: 'post',
                    data: data,
                    dataType: 'json',
                    success: function (response) {
                        if (response.success) {
                            $('#consent-tracking').modal('hide');
                        }
                    },
                    error: function (err) {
                        if (err.responseJSON && err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        }
                    }
                });
            });
        },
        error: function () {
            $('#consent-tracking').remove();
            $('#consent-tracking .modal-body').spinner().stop();
        }
    });
}

module.exports = function () {
    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')) {
        showConsentModal();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            showConsentModal();
        });
    }

    $('body').on('shown.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'true');
        $('#consent-tracking .affirm').focus();
    });

    $('body').on('hidden.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#consent-tracking', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#consent-tracking',
            firstElementSelector: '.affirm',
            lastElementSelector: '.decline',
            nextToLastElementSelector: '.affirm'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
};
