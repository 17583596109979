'use strict';

var swiper = require('swiper/bundle');
var Swiper = swiper.Swiper;
const { MEDIA_BREAKPOINTS } = require('./../constant');
const { insertSVG, arrowNavigationLeft, arrowNavigationRight } = require('../components/icons');

/**
 * Swiper config for Recommendations
 */
function initRecommendationSwiper() {
    var recommendationSwiper = new Swiper('.js-recommendations-swiper', {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 24,
        pagination: {
            el: '.swiper-custom-pagination',
            clickable: true,
            enabled: true
        },
        navigation: {
            nextEl: '.swiper-next-btn',
            prevEl: '.swiper-prev-btn'
        },
        breakpoints: {
            640: {
                slidesPerView: 1.5,
                spaceBetween: 16
            },
            768: {
                slidesPerView: 3.5,
                spaceBetween: 16
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 16
            }
        }
    });
}

/**
 * Swiper config for PDP Hero Carousel
 */
function initHeroCarousel() {
    var $heroSwiperElements = $('.experience-commerce_layouts-herocarousel .swiper');
    var swiperItems = [];
    if ($heroSwiperElements.length) {
        $heroSwiperElements.each(function () {
            var swiperConfig = $(this).data('swiper-config');
            var config = {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 24,
                pagination: {
                    el: '.swiper-custom-pagination',
                    clickable: true,
                    enabled: true
                },
                navigation: {
                    nextEl: '.swiper-next-btn',
                    prevEl: '.swiper-prev-btn'
                },
                breakpoints: {
                    [MEDIA_BREAKPOINTS.SMALL]: {
                        slidesPerView: swiperConfig.slidesPerViewSmall || 1,
                        spaceBetween: 16,
                        autoHeight: swiperConfig.autoHeightMobile || true,
                        pagination: {
                            enabled: swiperConfig.indicatorInSmall || true
                        }
                    },
                    [MEDIA_BREAKPOINTS.MEDIUM]: {
                        slidesPerView: swiperConfig.slidesPerViewMedium || 1,
                        slidesPerGroup: swiperConfig.slidesPerViewMedium || 1,
                        spaceBetween: 16,
                        pagination: {
                            enabled: swiperConfig.indicatorInMedium || true
                        }
                    },
                    [MEDIA_BREAKPOINTS.LARGE]: {
                        slidesPerView: swiperConfig.slidesPerViewLarge || 1,
                        slidesPerGroup: swiperConfig.slidesPerViewLarge || 1,
                        spaceBetween: 16,
                        pagination: {
                            enabled: swiperConfig.indicatorInLarge || true
                        }
                    }
                }
            };
            swiperItems.push(new Swiper(this, config));
        });

        // init next/prev carousel icons
        insertSVG(arrowNavigationLeft, 'icon-arrowNavigationLeft');
        insertSVG(arrowNavigationRight, 'icon-arrowNavigationRight');
    }
}

module.exports = {
    initRecommendationSwiper: initRecommendationSwiper,
    initHeroCarousel: initHeroCarousel
};
