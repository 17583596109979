'use strict';

var modalHelper = require('app_conairllc_sfra_browse/components/modalHelper');
var csValidation = require('app_conairllc_sfra_browse/components/clientSideValidation');

/**
 * Method to render reCAPTCHA in dynamically generated forms
 *  @param {string} modal accepts valid modal ID
 */
function renderRecaptcha(modal) {
    var $recaptchaNodes = $(modal).length && $(modal).find('form .g-recaptcha');
    if ($recaptchaNodes.length) {
        $recaptchaNodes.each(function () {
            if (!$(this).hasClass('g-recaptcha-rendered')) {
                // eslint-disable-next-line no-undef
                var widgetId = grecaptcha.render(this, {
                    sitekey: $(this).data('sitekey'),
                    callback: $(this).data('callback')
                });
                $(this).addClass('g-recaptcha-rendered');
                $(this).attr('data-widget-id', widgetId);
            }
        });
    }
}

module.exports = function () {
    $('body').on('click', '.dynamic-modal', function (e) {
        e.stopPropagation();
        e.preventDefault();
        var currentNode = $(this);
        var targetId = $(this).data('target-id');
        var labelledBy = $(this).data('labelled-by');
        var modalTitle = $(this).data('modal-title');
        var options = $(this).data('options');
        var customClass = $(this).data('custom-class');
        var parentModalId = $(this).data('parent-modal-id'); // prevent from showing nested modal at the same time
        var $modalBlock = modalHelper.modalBuilder(targetId, labelledBy, modalTitle);
        $('body').append($modalBlock);

        // to show loader till the time ajax response gets ready
        $.spinner().start();

        var url = $(this).data('url');
        if (url !== '') {
            $.ajax({
                method: 'get',
                url: $(this).data('url'),
                success: function (data) {
                    var code = $.parseHTML(data);
                    $('#' + targetId).find('.modal-title').html(modalTitle);
                    $('#' + targetId).find('.modal-body').html(code);

                    if (parentModalId) {
                        $('#' + parentModalId).modal('hide');
                        $('#' + parentModalId).on('hidden.bs.modal', function () {
                            $('#' + parentModalId).remove();
                            $('.modal-backdrop').remove();
                        });
                    }

                    if (options) {
                        $.spinner().stop();
                        $('#' + targetId).modal(options);
                    } else {
                        $.spinner().stop();
                        $('#' + targetId).modal('show');
                    }

                    if (currentNode) {
                        $('.dynamic-modal').removeClass('dynamic-modal-opened');
                        currentNode.addClass('dynamic-modal-opened');
                    }

                    if (customClass) {
                        $('#' + targetId).addClass(customClass);
                    }

                    // callback trigger when modal gets shown
                    $(document).on('shown.bs.modal', '#' + targetId, function () {
                        var $isReCaptchaEnabled = $(this).find('form input[name="reCAPTCHAEnabled"]');
                        // init form validation
                        if ($(this).find('form').length) {
                            csValidation.invalid();
                            csValidation.onBlur();
                            // Initialize reCAPTCHA when the modal is shown
                            if ($isReCaptchaEnabled.length && $isReCaptchaEnabled.val() === 'true') {
                                renderRecaptcha($(this));
                            }
                        }
                    });

                    // callback trigger when modal gets hidden
                    $(document).on('hidden.bs.modal', '#' + targetId, function () {
                        $('#' + targetId).remove();
                        $('.modal-backdrop').remove();
                    });
                    $.spinner().stop();
                },
                error: function (err) {
                    $.spinner().stop();
                }
            });
        }
    });
};
