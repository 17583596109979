'use strict';

const { toasterMessageDisplay } = require('app_conairllc_sfra_browse/utilities');
var modalHelper = require('app_conairllc_sfra_browse/components/modalHelper');
var csValidation = require('app_conairllc_sfra_browse/components/clientSideValidation');
var miniCart = require('./miniCart_base');
var updateShippingDiscount = require('../cart/cartUpdateShippingDiscount');
var updateMiniCart = true;
var itemAdded = false;

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    var status = data.success ? 'success' : 'error';
    var $miniCartModal = $('#minicart-panel-right');
    var $miniCartContent = $miniCartModal.find('.modal-content');
    toasterMessageDisplay(status, data.msg);
    if (data.success) {
        if (data.basket.numItems) {
            $('.minicart .minicart-quantity').empty().text(data.basket.numItems);
            $miniCartContent.empty().append(data.miniCartHtml);
            updateMiniCart = false;
            $.spinner().stop();
        } else {
            $('.minicart .minicart-quantity').empty().text(data.basket.numItems);
            $miniCartModal.modal('hide');
            $.spinner().stop();
        }
    }
}
/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function saveForLater() {
    $('body').on('click', '.product-move .move', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var pid = $(this).data('pid');
        var optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        var optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                if (data.redirect) {
                    if (data.isLoginModalEnabled) {
                        var modalId = 'loginModalforSFL';
                        modalHelper.showModalWithAjax(data.redirect, 'loginModalforSFL', function () {
                            // Success callback function
                            $('#' + modalId).modal('show');
                            $('#' + modalId).addClass('custom-modal');
                            $('#' + modalId).find('.wishlist-signin-msg').removeClass('d-none');
                            $('#' + modalId).on('shown.bs.modal', function () {
                                // init form validation
                                if ($(this).find('form').length) {
                                    csValidation.invalid();
                                    csValidation.onBlur();
                                }
                            });
                            $('#' + modalId).on('hidden.bs.modal', function () {
                                $('#' + modalId).remove();
                                $('.modal-backdrop').remove();
                            });
                            $.spinner().stop();
                        }, function (error) {
                            // Error callback function
                            $.spinner().stop();
                        });
                    } else {
                        window.location.href = data.redirect;
                    }
                } else {
                    displayMessageAndRemoveFromCart(data);
                }
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });
}

/**
 * custom function to close miniCart
 */
$('body').on('click', '.miniCartModal .close', function (e) {
    e.preventDefault();
    $('.minicart .popover').removeClass('show');
    // Reset the flag when the mini cart is manually closed
    itemAdded = false;
});

module.exports = function () {
    saveForLater();
    // cart();
    miniCart();
    updateShippingDiscount.updateShippingDiscountMessage();

    $('.minicart').on('count:update', function (event, count) {
        // added logic after add to cart open mini cart
        var isAutoCloseDisable = $('.minicart').data('closedisable');
        if (!count.error && (isAutoCloseDisable === true)) {
            updateMiniCart = true;
            var url = $('.minicart').data('action-url');
            if (count !== 0 && $('.minicart .popover.show').length === 0) {
                if (!updateMiniCart) {
                    $('.minicart .popover').addClass('show');
                    return;
                }

                $('.minicart .popover').addClass('show');
                $('.minicart .popover').spinner().start();
                $.get(url, function (data) {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);
                    updateMiniCart = false;
                    $.spinner().stop();
                });
            }
            itemAdded = true;
            $('.minicart').trigger('mouseenter');
        }
    });

    $('.minicart').on('mouseleave focusout', function (event) {
        event.preventDefault();
        // If the mini cart was opened due to an item being added, do not close it
        var isAutoCloseDisable = $('.miniCartModal').data('closedisable');
        if (itemAdded && (isAutoCloseDisable === true)) {
            return;
        }
        // end
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
        || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
        || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
};
