'use strict';

var verifyReCaptcha = require('./recaptchaVerify').verifyReCaptcha;

/**
 * Invokes verifyReCaptcha for newsletter
 * @param {string} token recaptcha token
 */
function newsletterRecaptchaResponse(token) {
    verifyReCaptcha(token, 'newsletter');
}

/**
 * Invokes verifyReCaptcha for login form
 * @param {string} token recaptcha token
 */
function loginRecaptchaResponse(token) {
    verifyReCaptcha(token, 'login');
}

/**
 * Invokes verifyReCaptcha for registeration form
 * @param {string} token recaptcha token
 */
function registerRecaptchaResponse(token) {
    verifyReCaptcha(token, 'register');
}

/**
 * Invokes verifyReCaptcha for changePassword form
 * @param {string} token recaptcha token
 */
function changePasswordRecaptchaResponse(token) {
    verifyReCaptcha(token, 'change-password');
}

/**
 * Invokes verifyReCaptcha for editProfile form
 * @param {string} token recaptcha token
 */
function editProfileRecaptchaResponse(token) {
    verifyReCaptcha(token, 'edit-profile');
}

/**
 * Invokes verifyReCaptcha for contactUs form
 * @param {string} token recaptcha token
 */
function contactUsRecaptchaResponse(token) {
    verifyReCaptcha(token, 'contact-us');
}

/**
 * Invokes verifyReCaptcha for trackOrder form
 * @param {string} token recaptcha token
 */
function trackOrderRecaptchaResponse(token) {
    verifyReCaptcha(token, 'track-order');
}

window.newsletterRecaptchaResponse = newsletterRecaptchaResponse;
window.loginRecaptchaResponse = loginRecaptchaResponse;
window.registerRecaptchaResponse = registerRecaptchaResponse;
window.changePasswordRecaptchaResponse = changePasswordRecaptchaResponse;
window.editProfileRecaptchaResponse = editProfileRecaptchaResponse;
window.contactUsRecaptchaResponse = contactUsRecaptchaResponse;
window.trackOrderRecaptchaResponse = trackOrderRecaptchaResponse;
