'use strict';

/**
 * re-renders the approaching discount messages
 * @param {Object} result - updated approaching discounts for the cart
 */
function updateShippingDiscount(result) {
    var html = '';
    var shippingDiscount = result.totals.discounts;
    var approachingDiscounts = result.approachingDiscounts;
    var isPromoMessageExists = false;
    if (shippingDiscount.length > 0) {
        $('.shipping-promotion-information').empty();
        shippingDiscount.forEach(function (item) {
            if (item.customType === 'SHIPPING') {
                html += '<span class="promotion-information shipping-promotion">'
                + item.detailsMsg + '</span>';
                isPromoMessageExists = true;
            }
        });
        $('.shipping-promotion-information').append(html);
        $('.shipping-promotion-information').removeClass('d-none');
    }

    if (approachingDiscounts.length > 0 && !isPromoMessageExists) {
        $('.approaching-discounts').empty();
        if (approachingDiscounts.length > 0) {
            approachingDiscounts.forEach(function (item) {
                html += '<div class="single-approaching-discount color--grey5 title-xs-bold border-0 ca-m-0">'
                    + item.discountMsg + '</div>';
            });
        }
        $('.approaching-discounts').append(html);
        $('.approaching-discounts').removeClass('d-none');
    }
}

module.exports = {
    updateShippingDiscountMessage: function () {
        $('body').on('cart:update cart:shippingMethodSelected promotion:success', function (data) {
            if (data && data.result && data.result.totals) {
                var result = data.result;
                updateShippingDiscount(result);
            }
        });
    }
};
