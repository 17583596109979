'use strict';

var createErrorNotification = require('base/components/errorNotification');

/**
 * Validates reCaptcha token on registration submit
 * @param {string} token recaptcha token
 * @param {string} pageType pagetype
 */
function verifyReCaptcha(token, pageType) {
    var $form = $('.js-form');
    if (pageType === 'newsletter') {
        $form = $('.newsletter-form');
    } else if (pageType === 'login') {
        $form = $('.login-js-form');
    } else if (pageType === 'register') {
        $form = $('.register-js-form');
    } else if (pageType === 'change-password') {
        $form = $('.change-password-js-form');
    } else if (pageType === 'edit-profile') {
        $form = $('.edit-profile-js-form');
    } else if (pageType === 'contact-us') {
        $form = $('.contactus-js-form');
    } else if (pageType === 'track-order') {
        $form = $('.trackorder-js-form');
    }

    var $submitBtn = $form.find('.js-submit-button');
    var verifyUrl = $submitBtn.data('verify-url');

    $form.spinner().start();
    $.ajax({
        url: verifyUrl,
        type: 'post',
        dataType: 'json',
        data: { token },
        success: function (data) {
            if (!data.success) {
                $form.spinner().stop();
                createErrorNotification($('.error-messaging'), data.errorMessage);
            } else {
                if (pageType === 'newsletter') {
                    if (document.querySelector('.newsletter-form').checkValidity()) {
                        $form.trigger('submit');
                    }
                } else if (pageType === 'login') {
                    if (document.querySelector('.login-js-form').checkValidity()) {
                        $form.trigger('submit');
                    }
                } else if (pageType === 'register') {
                    if (document.querySelector('.register-js-form').checkValidity()) {
                        $form.trigger('submit');
                    }
                } else if (pageType === 'change-password') {
                    if (document.querySelector('.change-password-js-form').checkValidity()) {
                        $form.trigger('submit');
                    }
                } else if (pageType === 'track-order') {
                    var trackOrderForm = document.querySelector('.trackorder-js-form');
                    if (trackOrderForm.checkValidity()) {
                        // calls the native submit method on the form element, bypassing any potential overrides or conflicts.
                        HTMLFormElement.prototype.submit.call(trackOrderForm);
                    }
                } else {
                    $form.trigger('submit');
                }
                $form.spinner().stop();
                var count = 0;
                $('.g-recaptcha').each(function () {
                    window.grecaptcha.reset(count);
                    count++;
                });
            }
        },
        error: function (err) {
            createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
        }
    });
}

module.exports = {
    verifyReCaptcha: verifyReCaptcha
};
