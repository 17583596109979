'use strict';

var base = require('./quickViewBase');
var focusHelper = require('base/components/focus');

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade quickViewModal panel panel-right" id="quickViewModal" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-body"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
    $('#quickViewModal .modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#quickViewModal .modal-body').empty();
            $('#quickViewModal .modal-body').html(parsedHtml.body);
            $('#quickViewModal .modal-footer').html(parsedHtml.footer);
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#quickViewModal .enter-message').text(data.enterDialogMessage);
            $('#quickViewModal').modal('show');
            $('body').trigger('quickview:ready');

            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

module.exports = {
    showQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this).closest('a.quickview').attr('href');
            $(e.target).trigger('quickview:show');
            getModalHtmlElement();
            fillModalElement(selectedValueUrl);
        });
    },
    focusQuickview: function () {
        $('body').on('shown.bs.modal', '#quickViewModal', function () {
            $('#quickViewModal .close').focus();
            $('#minicart-panel-right').modal('hide');
        });
    },
    closeQuickview: function () {
        $('body').on('hidden.bs.modal', '#quickViewModal', function () {
            // to avoid double scrollbar appearances
            if ($('#minicart-panel-right').hasClass('show')) {
                $('body').addClass('modal-open').css('padding-right', '17px');
            }
        });
    },
    trapQuickviewFocus: function () {
        $('body').on('keydown', '#quickViewModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickViewModal',
                firstElementSelector: '.quickViewModal  .product-name',
                lastElementSelector: '.add-to-cart-global',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    availability: base.availability,
    addToCart: base.addToCart,
    showSpinner: function () {
        $('body').on('product:beforeAddToCart product:beforeAttributeSelect', function (e, data) {
            if ($('.modal.show .product-quickview').length > 0) {
			    $(data).closest('.modal-content').spinner().start();
            }
        });
        $('body').on('product:beforeAddToCart product:beforeAttributeSelect', function () {
            if ($('.modal.show .product-quickview').length === 0) {
                $.spinner().start();
            }
        });
    },
    hideDialog: function () {
        $('body').on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');
        });
    },
    beforeUpdateAttribute: function () {
        $('body').on('product:beforeAttributeSelect', function () {
            if ($('.modal.show .product-quickview').length > 0) {
                $('.modal.show .modal-content').spinner().start();
            }
        });
    },
    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if ($('.modal.show .product-quickview').length > 0) {
                if ($('.modal.show .product-quickview>.bundle-items').length) {
                    $('.modal.show').find(response.container).data('pid', response.data.product.id);
                    $('.modal.show').find(response.container)
                        .find('.product-id').text(response.data.product.id);
                } else if ($('.set-items').length) {
                    response.container.find('.product-id').text(response.data.product.id);
                } else {
                    $('.modal.show .product-quickview').data('pid', response.data.product.id);
                }
            }
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            if ($('.modal.show .product-quickview').length > 0) {
                // update local add to cart (for sets)
                $('button.quickview-add-to-cart', response.$productContainer).attr(
                    'disabled',
                    (!response.product.readyToOrder || !response.product.available)
                );

                // update global add to cart (single products, bundles)
                var dialog = $(response.$productContainer)
                    .closest('.quick-view-dialog');

                $('.quickview-add-to-cart-global', dialog).attr(
                    'disabled',
                    !$('.global-availability', dialog).data('ready-to-order')
					|| !$('.global-availability', dialog).data('available')
                );
            }
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            if ($('.modal.show .product-quickview').length > 0) {
                // bundle individual products
                $('.product-availability', response.$productContainer)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);

                var dialog = $(response.$productContainer)
                    .closest('.quick-view-dialog');

                if ($('.product-availability', dialog).length) {
                    // bundle all products
                    var allAvailable = $('.product-availability', dialog).toArray()
                        .every(function (item) { return $(item).data('available'); });

                    var allReady = $('.product-availability', dialog).toArray()
                        .every(function (item) { return $(item).data('ready-to-order'); });

                    $('.global-availability', dialog)
                        .data('ready-to-order', allReady)
                        .data('available', allAvailable);

                    $('.global-availability .availability-msg', dialog).empty()
                        .html(allReady ? response.message : response.resources.info_selectforstock);
                } else {
                    // single product
                    $('.global-availability', dialog)
                        .data('ready-to-order', response.product.readyToOrder)
                        .data('available', response.product.available)
                        .find('.availability-msg')
                        .empty()
                        .html(response.message);
                }
            }
        });
    },
    updateDiscountMessage: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            var checkQuick = $('.modal.show .product-quickview').length > 0;
            if (response.data.product.showDiffPrice && response.data.product.price.priceDiff) {
                if (checkQuick) {
                    if (response.data.product.discountOverrideMessage) {
                        $('.product-quickview .price-diff').text(response.data.product.discountOverrideMessage);
                    } else {
                        $('.product-quickview .price-diff').text(response.data.product.price.priceDiff.formattedPriceText);
                    }
                } else if (response.data.product.discountOverrideMessage) {
                    $('.price-diff').text(response.data.product.discountOverrideMessage);
                } else {
                    $('.price-diff').text(response.data.product.price.priceDiff.formattedPriceText);
                }
            }
        });
    },
    updateColorName: base.updateColorName,
    updateLowStockValue: base.updateLowStockValue
};
