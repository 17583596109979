'use strict';

var baseSearch = require('base/search/search');
var controlDismiss = require('../../../../client/default/images/icons/controlDismiss.svg');
var refineSearch = require('../../../../client/default/images/icons/refineSearch.svg');
var controlPlus = require('../../../../client/default/images/icons/controlPlus.svg');
var controlMinus = require('../../../../client/default/images/icons/controlMinus.svg');
var LazyLoad = require('vanilla-lazyload');
var lazyLoadInstance = new LazyLoad();
const { insertSVG } = require('app_custom_sfra_recipe/wishlist/recipeWishlist');

/**
 * Updates the content of the specified DOM element with the content from the given results.
 * @param {jQuery} $results - The jQuery object containing the results.
 * @param {string} selector - The CSS selector of the DOM element to update.
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Updates SVG icons on the page with predefined SVG content.
 */
function updateSVGIcons() {
    // You can keep track of icons and their content in an object or array
    const iconMap = {
        'icon-controlDismiss': controlDismiss,
        'icon-refineSearch': refineSearch,
        'icon-controlMinus': controlMinus,
        'icon-controlPlus': controlPlus
    };
    Object.keys(iconMap).forEach(index => {
        insertSVG(iconMap[index], index);
    });
}

const updatePageUrlParams = ($target) => {
    const urlData = $target.data('page-url-options');
    var queryParams = new URLSearchParams();
    if (urlData && urlData.length) {
        urlData.map(item => queryParams.set(item.name, item.value));
        window.history.replaceState(null, null, `?${queryParams.toString()}`);
    }
};
/**
 * Handles the display of active refinements.
 * @param {jQuery} $results - The jQuery object containing the updated search results.
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}
/**
 * Updates the product count displayed on the page.
 * @param {string} response - The response containing updated HTML.
 */
function updateCount(response) {
    let productCountTopContent = $(response).find('#result-count-display .product-count-top').html();
    $('.result-count-heading .product-count-top').html(productCountTopContent);
}
/**
 * Parses the results of an AJAX call and updates the DOM accordingly.
 * @param {string} response - The response containing updated HTML.
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.search-bar',
        '.no-products',
        '.totals-mb',
        '.res-count'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}
/**
 * Handles the sort order selection.
 */
function sort() {
    // Handle sort order menu selection
    $('.container').on('click', '.sort-order .dropdown-item', function (e) {
        e.preventDefault();

        $.spinner().start();
        $(this).trigger('search:sort', this.value);
        $.ajax({
            url: this.value,
            data: { selectedUrl: this.value },
            method: 'GET',
            success: function (response) {
                parseResults(response);
                updatePageUrlParams($('.grid-footer'));
                $('body').trigger('page:wishlistedIconChangePLP');
                if (lazyLoadInstance) {
                    lazyLoadInstance.update();
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}
/**
 * Handles the application of filters.
 */
function applyFilter() {
    // Handle refinement value selection and reset click
    $('.container').on(
        'click',
        '.refinements li input[type="checkbox"], .refinement-bar button.reset, .filter-value button, .swatch-filter button',
        handleFilter
    ).on(
        'change',
        '.refinements li input[type="radio"]',
        handleFilter
    );
}

function handleFilter(e) {
    e.preventDefault();
    e.stopPropagation();

    $.spinner().start();
    var attributeId = '#' + $(this).find('span').last().attr('id');
    $.ajax({
        url: $(this).data('href'),
        data: {
            page: $('.grid-footer').data('page-number'),
            selectedUrl: $(this).data('href')
        },
        method: 'GET',
        success: function (response) {
            parseResults(response);
            updatePageUrlParams($('.grid-footer'));
            updateSVGIcons();
            $(attributeId).parent('button').focus();
            if (lazyLoadInstance) {
                lazyLoadInstance.update();
            }
            $('body').trigger('page:wishlistedIconChangePLP');
            if ($('.product-listing-page').length) {
                $('html, body').animate({ scrollTop: $('.product-listing-page').offset().top - 150 }, 300);
            } else if ($('.recipe-listing-page').length) {
                $('html, body').animate({ scrollTop: $('.recipe-listing-page').offset().top }, 300);
            }
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
            $(attributeId).parent('button').focus();
        }
    });
}
/**
 * Handles the "Show More" functionality.
 */
function showMore() {
    // Show more products
    $('.container').on('click', '.show-more button', function (e) {
        e.stopPropagation();
        var showMoreUrl = $(this).data('url');
        e.preventDefault();

        $.spinner().start();
        $(this).trigger('search:showMore', e);
        $.ajax({
            url: showMoreUrl,
            data: { selectedUrl: showMoreUrl },
            method: 'GET',
            success: function (response) {
                $('.grid-footer').replaceWith(response);
                updateCount(response);
                $('body').trigger('page:wishlistedIconChangePLP');
                if (lazyLoadInstance) {
                    lazyLoadInstance.update();
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}
/**
 * Handles the selection of showing order.
 */
function showing() {
    // Handle sort order menu selection
    $('.container').on('click', '.showing-order .dropdown-item', function (e) {
        e.preventDefault();

        $.spinner().start();
        $.ajax({
            url: this.value,
            data: { selectedUrl: this.value },
            method: 'GET',
            success: function (response) {
                parseResults(response);
                updatePageUrlParams($('.grid-footer'));
                $('body').trigger('page:wishlistedIconChangePLP');
                if (lazyLoadInstance) {
                    lazyLoadInstance.update();
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}
/**
 * Handles the closing model on mobile back button.
 */
function closeRefinements() {
    // Refinements close button
    $('.container').on('click', '.refinement-bar .close, .modal-background', function () {
        $('.refinement-bar, .modal-background').hide();
        $('.refinement-bar').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
        $('.btn.filter-results').focus();
    });
}

$(function () {
    $('body').on('page:wishlistedIconChangePLP', function (e, data) {
        updateSVGIcons();
    });
});

var exportSearch = $.extend({}, baseSearch, {
    showing: showing,
    showMore: showMore,
    applyFilter: applyFilter,
    sort: sort,
    closeRefinements: closeRefinements
});

module.exports = exportSearch;
