'use strict';

require('./components/swiper-carousel');
require('./product/pdpCarousel');

const { ajaxRequest, toasterMessageDisplay } = require('app_conairllc_sfra_browse/utilities');
const { recipesWishlistedChangeIcon } = require('app_custom_sfra_recipe/wishlist/recipeWishlist');
const { reFillHeartIcon } = require('plugin_wishlists_custom/product/wishlistHeart');

var LazyLoad = require('vanilla-lazyload');
var videoPlayer = require('./videoPlayer/videoPlayer');
const { initRecipeBottomSwiper } = require('app_custom_sfra_recipe/recipe/recipe');
const { initRecommendationSwiper, initHeroCarousel } = require('./components/lazyload-swiper');

$(function () {
    // Image lazyloading
    var lazyLoadInstance = new LazyLoad();

    // Accordian initilization
    $('.accordion-component').collapse({ toggle: false });

    // Tooltip initilization
    $('[data-toggle="tooltip"]').tooltip();

    // Back to top functionality
    $(window).scroll(function () {
        if ($(this).scrollTop()) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });

    $('#back-to-top').click(function () {
        $('html, body').animate({ scrollTop: 0 }, 1000);
    });

    // Youtube Player initilization
    if ($('.js-video-player-component-init').length) {
        videoPlayer.initVideoComponentPreviewButton();
    }

    // Check if the social container exists on the page
    if ($('.social-container').length) {
        // Toggle visibility of the social container on button click
        $('.social-share-toggle').on('click', function(e) {
            e.preventDefault();
            var $socialContainer = $(this).closest('.social-media-popover').find('.social-container');
            $socialContainer.toggleClass('d-none');
            e.stopPropagation(); // Prevent the click from propagating to the document
        });

        // Hide the social container when clicking outside
        $(document).on('click', function(e) {
            var $socialContainer = $('.social-container');
            // Check if the click is outside the social container and it is visible
            if (!$socialContainer.is(e.target) && $socialContainer.has(e.target).length === 0 && !$socialContainer.hasClass('d-none')) {
                $socialContainer.addClass('d-none');
            }
        });
    }

    // Initialize Intersection Observe
    const observer = new IntersectionObserver(lazyLoadContent, {
        root: null,
        rootMargin: '0px',
        threshold: 1
    });

    // Tabs-mobile
    $('.js-tabs-mobile a, .js-tabs-mobile button').on('click', function (e) {
        e.preventDefault();
        var tabsParent = $(this).closest('.tabs');
        $(tabsParent).find('.tab-content').children().removeClass('active show');
        $(tabsParent).find('.' + $(this).data('val')).addClass('active show');
    });

    // Simulate what happens when .ajaxStart is called
    // AjaxStop Simply hides this div.
    $(document).on('ready', function () {
        $('.show-spinner').on('click', function () {
            $.spinner().start();
        }).on('blur', function () {
            $.spinner().stop();
        });
    });

    $('body').on('input', '#registration-form-phone', function (event) {
        var input = event.target.value;
        var regex = /^[0-9-()]*$/;

        if (!regex.test(input)) {
            event.target.value = input.replace(/[^0-9]/g, '');
        }
    });

    /**
     * Handles lazy loading of content for placeholder divs when they come into view.
     * @param {IntersectionObserverEntry[]} entries - An array of IntersectionObserverEntry objects.
     * @param {IntersectionObserver} observer - The IntersectionObserver instance.
     */
    function lazyLoadContent(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Get the placeholder div
                const placeholderDiv = entry.target;

                // Check if the div is marked for lazy loading
                if ($(placeholderDiv).attr('data-loading') === 'lazy') {
                    // Get the URL for the AJAX request
                    loadAjaxContent_forLazyloadSection(placeholderDiv, observer, null);
                }
            }
        });
    }

    function loadAjaxContent_forLazyloadSection(placeholderDiv, observer, callbackFn) {
        const requestUrl = $(placeholderDiv).data('request-url');

        // Make AJAX request
        $.ajax({
            url: requestUrl,
            method: 'GET',
            success: function (response) {
                // Fill in the content-lazy-loaded div with the response
                $(placeholderDiv).find('.content-lazy-loaded').html(response);

                // Update the data-loading attribute to mark as loaded
                $(placeholderDiv).attr('data-loading', 'loaded');

                $('body').trigger('page:lazyLoadContentPDP', placeholderDiv);
                // Disconnect the observer once the content is loaded
                if (observer) {
                    observer.unobserve(placeholderDiv);
                }

                callbackFn && callbackFn();
            },
            error: function (xhr, status, error) {
                console.error('Error loading content:', error);
            }
        });
    };

    // *** Can be used in every page, parent div should have 'lazyload' class ***
    // Observe all elements with data-loading="lazy"
    $('.lazyload [data-loading="lazy"]').each(function () {
        observer.observe(this);
    });

    $('body').on('click', '.bv_ratings_summary, .bv_button_component_container, .bv_histogram_row_container', function(){
        var $contentAreaRatingAndReview = '.content-area-ratingAndReview';
        if($($contentAreaRatingAndReview).attr('data-loading') == 'lazy') {
            loadAjaxContent_forLazyloadSection($contentAreaRatingAndReview, null, function(){
                $('html, body').animate({ scrollTop: $($contentAreaRatingAndReview).offset().top - 150 }, 300);
                setTimeout(() => {
                    $('html, body').animate({ scrollTop: $($contentAreaRatingAndReview).offset().top - 150 }, 0);
                }, 1300);
            });
        }
    });

    $('body').on('page:lazyLoadContentPDP', function (e, data) {
        recipesWishlistedChangeIcon();
        reFillHeartIcon();
        if ($(data).hasClass('content-area-recipeSection')) {
            initRecipeBottomSwiper(3);
        }
        if ($(data).find('.recommended-products').length) {
            initRecommendationSwiper();
        }
        if ($(data).hasClass('content-area-contentSection')) {
            initHeroCarousel();
        }
    });

    // truncate text
    $('body').on('click', '.js-show-more', function (e) { truncateText(e); });

    // Quanity selector
    $('body').on('change', '.qty-selector .quantity-field', function (e) {
        $(this).parent().parent().find('.quantity-select')
            .val(e.target.value)
            .trigger('change');
    });

    $(window).on('scroll', function () {
        var scrollTop = $(window).scrollTop();
        if (scrollTop > $('.header-banner').outerHeight()) {
            $('.header').addClass('header-fixed');
            $('body').css('padding-top', $('.header').outerHeight());
        } else {
            $('.header').removeClass('header-fixed');
            $('body').css('padding-top', 0);
        }
    });

    $('.main-menu #closeButton').on('click', function () {
        $('.main-menu .navbar .dropdown-menu').removeClass('show');
    });

    // Function to stop the video
    function stopVideo() {
        $('.hero-carousel-play-btn').each(function () {
            const button = $(this);
            const youtubePlayer = button.closest('.image-wrapper').find('iframe').get(0);
            const videoPlayer = button.closest('.image-wrapper').find('video').get(0);
            $(button).find('.play-btn-text').text('Play');
            $(button).data('play', false).find('.bg-svg-icon').removeClass('bg-icon-controlPause')
                .addClass('bg-icon-controlPlay');
            $(button).closest('.image-wrapper').find('.youtube-iframe-parent').addClass('d-none');
            if (youtubePlayer) {
                youtubePlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            } else if (videoPlayer) {
                videoPlayer.pause();
            }
        });
    }

    // Video Player for Hero Banner
    $('body').on('click', '.hero-carousel-play-btn', function () {
        var button = $(this);
        var youtubePlayer = $(button).closest('.image-wrapper').find('iframe').get(0);
        var videoPlayer = $(button).closest('.image-wrapper').find('video').get(0);

        if ($(button).data('play') === false) {
            $(button).data('play', true);
            $(button).find('.play-btn-text').removeClass('d-flex').addClass('d-none');
            $(button).find('.pause-btn-text').removeClass('d-none').addClass('d-flex');

            // to hide cover image and show video
            $(button).closest('.image-wrapper').find('.youtube-iframe-parent').removeClass('d-none');
            if (youtubePlayer) {
                youtubePlayer.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                youtubePlayer.contentWindow.postMessage('{"event":"command","func":"mute","args":""}', '*');
            } else if (videoPlayer) {
                videoPlayer.play();
            }
        } else {
            $(button).data('play', false);
            $(button).find('.play-btn-text').removeClass('d-none').addClass('d-flex');
            $(button).find('.pause-btn-text').removeClass('d-flex').addClass('d-none');

            // to show cover image and hide video
            $(button).closest('.image-wrapper').find('.youtube-iframe-parent').addClass('d-none');

            if (youtubePlayer) {
                youtubePlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            } else if (videoPlayer) {
                videoPlayer.pause();
            }
        }
    });
    $('.show-more-button').on('click', function () {
        $(this).parent('.showmore-Container').siblings('.showTextToggler').addClass('showTextToggler-expanded');
        $(this).addClass('d-none');
        $(this).siblings('.show-less-button').removeClass('d-none');
    });
    $('.show-less-button').on('click', function () {
        $(this).parent('.showmore-Container').siblings('.showTextToggler').removeClass('showTextToggler-expanded');
        $(this).addClass('d-none');
        $(this).siblings('.show-more-button').removeClass('d-none');
    });
});

// truncate text function
function truncateText(e) {
    var $button = $(e.target);
    var $longContent = $button.prev('.js-long-content');
    var moreMsg = $button.data('moremsg');
    var lessMsg = $button.data('lessmsg');

    $longContent.toggleClass('truncate-text-2');
    $button.text($longContent.hasClass('truncate-text-2') ? moreMsg : lessMsg);
}

/**
 * Updates or appends a query parameter to the given URI.
 *
 * This function checks if the specified query parameter already exists in the URI.
 * If it exists, the function updates its value. If it does not exist, the function appends
 * the query parameter with the given value to the URI.
 *
 * @param {string} uri - The URI to be updated.
 * @param {string} key - The name of the query parameter to be updated or added.
 * @param {string} value - The value of the query parameter to be set.
 * @returns {string} - The updated URI with the query parameter set to the specified value.
 */
function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    var separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
    }
    return uri + separator + key + '=' + value;
}

$('.navbar-toggler').on('click', function () {
    if ($('.main-menu').hasClass('in')) {
        $('.close-x').removeClass('d-none');
        $('.hamburger').addClass('d-none');
    } else {
        $('.close-x').addClass('d-none');
        $('.hamburger').removeClass('d-none');
    }
});

$('.navbar-toggler').on('click', '.hamburger', function () {
    $('.modal-background').css({ backgroundColor: 'transparent' });
});

$('.product-button').on('click', function () {
    var currentUrl = window.location.href;
    var newUrl = updateQueryStringParameter(currentUrl, 'gridtype', 'product');
    window.history.replaceState({}, document.title, newUrl);
});

$('.recipe-button').on('click', function () {
    var currentUrl = window.location.href;
    var newUrl = updateQueryStringParameter(currentUrl, 'gridtype', 'recipe');
    window.history.replaceState({}, document.title, newUrl);
});

var $modalRelatedContent = $('.modal-relatedContent');
var $modalRelatedContentVideo = $modalRelatedContent.find('video');
var $modalRelatedContentIframe = $modalRelatedContent.find('iframe');

$('.related-content-button').on('click', function () {
    var configObject = $(this).data('config');

    if (configObject && (configObject.video || configObject.youtubeVideoID)) {
        var isVideo = configObject.video && configObject.video !== '';

        $modalRelatedContentVideo.attr('src', isVideo ? configObject.video : '');
        $modalRelatedContentIframe.attr('src', isVideo ? '' : 'https://www.youtube.com/embed/' + configObject.youtubeVideoID + '?enablejsapi=1&html5=1&rel=0&showinfo=0');

        $modalRelatedContentVideo.toggle(isVideo);
        $modalRelatedContentIframe.toggle(!isVideo);
    }
});

$modalRelatedContent.on('shown.bs.modal', function () {
    $modalRelatedContentVideo.get(0).play();
    $modalRelatedContentIframe.get(0).contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
});

$modalRelatedContent.on('hidden.bs.modal', function () {
    $modalRelatedContentVideo.get(0).pause();
    $modalRelatedContentIframe.get(0).contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
});

$('#collapseTrackOrderForm').on('show.bs.collapse', function () {
    $('.btn-toggle-trackorder-form').addClass('d-none');
});

// Toaster notification for GTM tracker
$('body').on('page:toasterDisplay', function (e, data) {});

$('.footer-subscription-form').on('submit', function (e) {
    e.preventDefault();
    var form = $(this);
    var button = $(form).find('.subscribe-email-footer');
    var url = $(button).data('href');
    var emailInput = $(form).find('input[name=hpEmailSignUp]');
    var emailId = $(emailInput).val();
    $.spinner().start();
    $(this).attr('disabled', true);
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
            emailId: emailId
        },
        success: function (data) {
            if (data.success) {
                toasterMessageDisplay('success', data.msg);
                $.spinner().stop();
                $('.success-sign-up').removeClass('d-none');
                button.removeAttr('disabled');
                $(emailInput).val('');
                setTimeout(function () {
                    $('.success-sign-up').addClass('d-none');
                }, 5000);
            } else {
                toasterMessageDisplay('error', data.msg);
                $.spinner().stop();
                button.removeAttr('disabled');
                $('.success-sign-up').addClass('d-none');
            }
        },
        error: function (error) {
            toasterMessageDisplay('error', error.msg);
            $.spinner().stop();
            button.removeAttr('disabled');
            $('.success-sign-up').addClass('d-none');
        }
    });
});
