'use strict';

/**
 * Generic Ajax call method
 * @param {Object} ajaxParams Ajax object like method, url , data
 */
function ajaxRequest(ajaxParams) {
    // Default options
    var settings = $.extend({
        url: '',
        method: 'GET',
        data: null,
        dataType: 'json',
        contentType: 'application/json',
        successCallbackFunction: function (response) {},
        errorCallBackFunction: function (xhr, status, error) {}
    }, ajaxParams);

    try {
        if (ajaxParams && settings.url) {
            $.ajax({
                url: settings.url,
                type: settings.method,
                data: settings.data,
                dataType: settings.dataType,
                contentType: settings.contentType,
                processData: false,
                success: function (response) {
                    if (typeof settings.successCallbackFunction === 'function') {
                        settings.successCallbackFunction(response);
                    }
                },
                error: function (xhr, status, error) {
                    if (typeof errorCallBackFunction === 'function') {
                        settings.errorCallBackFunction(xhr, status, error);
                    }
                }
            });
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * Generic toaster Message Listner
 * @param {string} type Toaster type like success, error
 * @param {string} message Toaster Message
 */
function toasterMessageDisplay(type, message) {
    if ((type === 'success' || type === 'error' || type === 'warning') && message) {
        try {
            var timeOut = $('.footer').data('globaltoastertimeout');
            var toasterType = {
                success: '<div class="alert notification success ca-mb-12"><div class="notification__body"><div class="bg-svg-icon bg-icon-notificationCheckCircle notification__img"></div><span class="text-standard"></span></div><button type="button" class="ca-ml-8 bg-svg-icon bg-icon-controlDismiss" data-dismiss="alert" aria-label="Close"></button></div>',
                error: '<div class="alert notification warning ca-mb-12"><div class="notification__body"><div class="bg-svg-icon bg-icon-notificationErrorInvert notification__img"></div><span class="text-standard"></span></div><button type="button" class="ca-ml-8 bg-svg-icon bg-icon-controlDismiss" data-dismiss="alert" aria-label="Close"></button></div>',
                warning: '<div class="alert notification warning ca-mb-12"><div class="notification__body"><div class="bg-svg-icon bg-icon-notificationErrorInvert notification__img"></div><span class="text-standard"></span></div><button type="button" class="ca-ml-8 bg-svg-icon bg-icon-controlDismiss" data-dismiss="alert" aria-label="Close"></button></div>'
            };
            $('.pageLevel-messages').remove();
            if ($('.pageLevel-messages').length === 0) {
                $('body').append('<div class="pageLevel-messages"></div>');
                $('.pageLevel-messages').prepend(toasterType[type]).find('.notification .text-standard').html(message);
                clearTimeout(timeOut);
                setTimeout(function () {
                    $('.pageLevel-messages').remove();
                }, timeOut);
            }
        } catch (errorMsg) {
            console.log('There is some error', errorMsg);
        }
    }
}

module.exports = { ajaxRequest, toasterMessageDisplay };
