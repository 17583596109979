'use strict';

const { toasterMessageDisplay } = require('app_conairllc_sfra_browse/utilities');
var modalHelper = require('app_conairllc_sfra_browse/components/modalHelper');
var csValidation = require('app_conairllc_sfra_browse/components/clientSideValidation');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked to add a product to the wishlist
 */
function displayMessage(data, button) {
    $.spinner().stop();
    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages"></div>');
    }
    if (data.success) {
        if (button.hasClass('active') || button.children().hasClass('active')) {
            button.removeClass('active') && button.children().removeClass('active');
            toasterMessageDisplay('success', (data.msg));
        } else {
            button.addClass('active') && button.children().addClass('active');
            toasterMessageDisplay('success', data.msg);
        }
    } else {
        toasterMessageDisplay('error', (data.msg || data.statusText));
    }

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

/**
* Concatenate new product wishlist id in the header
 * @param {string} wishlistIDs - data returned from the server's ajax call
*/
function updateProductWishlistIdInHeader(wishlistIDs) {
    // Get the current value of the data-users-wishlisted-product-ids attribute
    var wishlistProductIds = ($('.js-wishlist-pids').data('wishlist-product-ids') || '').split(',');

    wishlistProductIds = wishlistIDs;

    // Set the new value of the data-wishlisted-product-ids attribute
    $('.js-wishlist-pids').data('wishlist-product-ids', wishlistProductIds);
}

module.exports = {
    reFillHeartIcon: function () {
        var wishlistProductIds = ($('.js-wishlist-pids').data('wishlist-product-ids') || '').split(',');

        // un-fill all the the icon
        $('.product-detail .top-icon-wrapper .like-bg-btn').removeClass('active');
        $('.product-card').each(function () {
            $('.product-card').find('.like-bg-btn').removeClass('active');
        });

        // PDP
        var pdpProductId = $('.product-detail').data('wishlist-pid');
        if (wishlistProductIds.indexOf(pdpProductId) !== -1) {
            $('.product-detail .top-icon-wrapper .like-bg-btn').addClass('active');
        }

        // PLP
        $('.product-card').each(function () {
            var tile = $(this);
            var productId = tile.data('wishlist-pid');
            if (wishlistProductIds.indexOf(productId) !== -1) {
                tile.find('.like-bg-btn').addClass('active');
            }
        });
    },
    onloadFillHeartIcon: function () {
        this.reFillHeartIcon();
    },
    onFilterApplied: function () {
        var wishlistHeart = this;
        $('body').on('page:wishlistedIconChangePLP', function () {
            wishlistHeart.reFillHeartIcon();
        });
    },
    WishlistToggle: function () {
        $('body').on('click', '.js-wishlist-cta-product', function (e) {
            e.preventDefault();
            var button = $(this);
            var pid = $(this).closest('.product-detail, .product-card').data('wishlist-pid');
            var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            var url;
            var type;
            var jsonData;
            if (button.hasClass('active') || button.children().hasClass('active')) {
                url = $(this).data('remove-url');
                url = url + '?pid=' + pid;
                type = 'get';
            } else {
                url = $(this).data('add-url');
                type = 'post';
                jsonData = {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                };
            }
            if (!url || !pid) {
                return;
            }
            $.spinner().start();
            $.ajax({
                url: url,
                type: type,
                dataType: 'json',
                data: jsonData,
                success: function (data) {
                    if (data.redirect) {
                        if (data.isLoginModalEnabled) {
                            var modalId = 'loginModalforProdWL';
                            modalHelper.showModalWithAjax(data.redirect, modalId, function () {
                                // Success callback function
                                $('#' + modalId).modal('show');
                                $('#' + modalId).addClass('custom-modal');
                                $('#' + modalId).find('.wishlist-signin-msg').removeClass('d-none');
                                $('#' + modalId).on('shown.bs.modal', function () {
                                    // init form validation
                                    if ($(this).find('form').length) {
                                        csValidation.invalid();
                                        csValidation.onBlur();
                                    }
                                });
                                $('#' + modalId).on('hidden.bs.modal', function () {
                                    $('#' + modalId).remove();
                                    $('.modal-backdrop').remove();
                                });
                                $.spinner().stop();
                            }, function (error) {
                                // Error callback function
                                $.spinner().stop();
                            });
                        } else {
                            window.location.href = data.redirect;
                        }
                    } else {
                        $('body').trigger('search:wishlist:addproduct', data);
                        if ($('.js-favorite-list').length > 0) {
                            displayMessage(data, button);
                            window.location.reload();
                        } else {
                            displayMessage(data, button);
                            if (data.wishlistPIds && !data.error) {
                                updateProductWishlistIdInHeader(data.wishlistPIds);
                            }
                            $.spinner().stop();
                        }
                    }
                },
                error: function (err) {
                    displayMessage(err, button);
                }
            });
        });
    }
};
