'use strict';

const { toasterMessageDisplay } = require('app_conairllc_sfra_browse/utilities');
var heartInvert = require('../../images/icon/heartInvert.svg');
var modalHelper = require('app_conairllc_sfra_browse/components/modalHelper');
var csValidation = require('app_conairllc_sfra_browse/components/clientSideValidation');
const { reFillHeartIcon } = require('plugin_wishlists_custom/product/wishlistHeart');

// Function to insert SVG into HTML
const insertSVG = (svgString, targetClass) => {
    const targetElements = document.querySelectorAll(`.${targetClass}`);
    if (targetElements) {
        targetElements.forEach((targetElement) => {
            targetElement.innerHTML = svgString;
        });
    }
};
/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, button) {
    $.spinner().stop();
    if (data.success) {
        if (button.hasClass('active')) {
            button.removeClass('active');
            toasterMessageDisplay('success', (data.msg));
        } else {
            button.addClass('active');
            toasterMessageDisplay('success', data.msg);
        }
    } else {
        toasterMessageDisplay('error', (data.msg || data.statusText));
    }
}

/**
* Changes Heart icon to fill for the recipes in the wishlist
 */
function recipesWishlistedChangeIcon() {
    // Get recipe IDs from wishlist array
    var recipeList = $('.js-recipe-ids').length && $('.js-recipe-ids').data('users-wishlisted-recipe-ids') && $('.js-recipe-ids').data('users-wishlisted-recipe-ids').split(',');

    var recipeDetailsId = $('.js-rdp-id').data('rid');// Assuming product ID is stored in a data attribute

    // If recipe ID is in wishlist, fill heart icon for recipe details page
    if (recipeList && recipeList.indexOf(recipeDetailsId) !== -1) {
        var elm = $('.top-icon-wrapper .like-bg-btn');
        $(elm).addClass('active');
    }

    // Loop through recipe tiles on recipe listing page
    $('.js-rlp-id').each(function () {
        var tile = $(this).data('rid'); // Assuming recipe ID is stored in a data attribute

        // If recipe ID is in wishlist, fill heart icon
        if (recipeList && recipeList.indexOf(tile) !== -1) {
            $(this).siblings('.like-bg-btn').addClass('active');
        }
    });
}

/**
* Concatenate new recipes wishlist id in the header
 * @param {string} data - data returned from the server's ajax call
*/
function updateRecipeWishlistIdInHeader(data) {
    // Get the current value of the data-users-wishlisted-recipe-ids attribute
    var wishlistedRecipeIds = $('.js-recipe-ids').data('users-wishlisted-recipe-ids');

    // Concatenate the new value to the list
    var addedRecipeID = data.rid;
    if (wishlistedRecipeIds.length) {
        wishlistedRecipeIds += (',' + addedRecipeID);
    } else {
        wishlistedRecipeIds = addedRecipeID;
    }

    // Set the new value of the data-users-wishlisted-recipe-ids attribute
    $('.js-recipe-ids').data('users-wishlisted-recipe-ids', wishlistedRecipeIds);
}

// TODO Ganesh-Piyush
$(document).ready(function () {
    $('body').on('click', '.js-wishlist-button', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'html',
            success: function (response) {
                var favoriteList = $('.js-favorite-list');
                favoriteList.empty();
                favoriteList.append(response);
                insertSVG(heartInvert, 'icon-heartInvert');
                recipesWishlistedChangeIcon();
                reFillHeartIcon();
                $.spinner().stop();
            },
            error: function (error) {
                $.spinner().stop();
            }
        });
    });
});

$(document).ready(function () {
    $('body').on('click', '.js-wishlist-button-chip', function (e) {
        e.preventDefault();
        $.spinner().start();
        window.location.reload();
    });
});

$(document).ready(function () {
    recipesWishlistedChangeIcon();

    jsWishlistCtaRecipeInit();
});

/**
 * Initializes the wishlist call-to-action functionality for recipe items.
 * Attaches click event handlers to elements with the class 'js-wishlist-cta-recipe'.
 * Handles addition or removal of recipes from the user's wishlist.
 * Displays appropriate messages and updates UI elements based on the AJAX response.
 */
function jsWishlistCtaRecipeInit() {
    $('body').off('click', '.js-wishlist-cta-recipe');
    $('body').on('click', '.js-wishlist-cta-recipe', function (e) {
        e.preventDefault();
        var button = $(this);
        var rid = $(this).siblings('.js-rdp-id').data('rid');
        if (!rid) {
            rid = $(this).siblings('.js-rlp-id').data('rid');
        }
        var url;
        var type;
        var jsonData;
        if (button.hasClass('active')) {
            url = $(this).data('remove-url');
            url = url + '?cid=' + rid;
            type = 'get';
        } else {
            url = $(this).data('add-url');
            type = 'post';
            jsonData = {
                cid: rid
            };
        }
        if (!url || !rid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: type,
            dataType: 'json',
            data: jsonData,
            success: function (data) {
                if (data.redirect) {
                    if (data.isLoginModalEnabled) {
                        var modalId = 'loginModalforWL';
                        modalHelper.showModalWithAjax(data.redirect, modalId, function () {
                            // Success callback function
                            $('#' + modalId).modal('show');
                            $('#' + modalId).addClass('custom-modal');
                            $('#' + modalId).find('.wishlist-signin-msg').removeClass('d-none');
                            $('#' + modalId).on('shown.bs.modal', function () {
                                // init form validation
                                if ($(this).find('form').length) {
                                    csValidation.invalid();
                                    csValidation.onBlur();
                                }
                            });
                            $('#' + modalId).on('hidden.bs.modal', function () {
                                $('#' + modalId).remove();
                                $('.modal-backdrop').remove();
                            });
                            $.spinner().stop();
                        }, function (error) {
                            // Error callback function
                            $.spinner().stop();
                        });
                    } else {
                        window.location.href = data.redirect;
                    }
                } else if ($('.js-favorite-list').length > 0) {
                    displayMessageAndChangeIcon(data, button);
                    window.location.reload();
                } else {
                    displayMessageAndChangeIcon(data, button);
                    if (data.rid) {
                        updateRecipeWishlistIdInHeader(data);
                    }
                }
            },
            error: function (err) {
                displayMessageAndChangeIcon(err, button);
            }
        });
    });
}

module.exports = {
    recipesWishlistedChangeIcon: recipesWishlistedChangeIcon,
    insertSVG: insertSVG
};
