'use strict';

var Swiper = require('swiper/bundle').Swiper;
const { MEDIA_BREAKPOINTS } = require('./../constant');
const breakpoint = window.matchMedia('(max-width:47.938em)');
let swiperItems = [];
var allPDSwiperEl = $('.experience-commerce_layouts-carousel, .experience-commerce_layouts-herocarousel, .experience-commerce_layouts-clpSubCategories').find('.swiper');

const breakpointChecker = function () {
    if (breakpoint.matches === true) {
        allPDSwiperEl && allPDSwiperEl.each(function (i, swiperEl) {
            if ($(swiperEl).data('swiper-config').showAsCardsMobile) {
                if (swiperItems[i] !== undefined) swiperItems[i].destroy(true, true);
                $(allPDSwiperEl[i]).find('.swiper-slide').addClass('two-card-view');
                $(swiperEl).find('.swiper-control-wrapper').addClass('d-none');
            } else {
                swiperItems = [];
                var swiperConfig = $(swiperEl).data('swiper-config');
                enableSwiper(swiperConfig, swiperEl);
                $(allPDSwiperEl[i]).find('.swiper-slide').removeClass('two-card-view');
                $(allPDSwiperEl[i]).find('.swiper-control-wrapper').removeClass('d-none');
            }
        });
    } else if (breakpoint.matches === false) {
        swiperItems = [];
        allPDSwiperEl && allPDSwiperEl.each(function (i, swiperEl) {
            var swiperConfig = $(swiperEl).data('swiper-config');
            enableSwiper(swiperConfig, swiperEl);
            $(allPDSwiperEl[i]).find('.swiper-slide').removeClass('two-card-view');
            $(allPDSwiperEl[i]).find('.swiper-control-wrapper').removeClass('d-none');
        });
    }
};

const enableSwiper = function (swiperConfig, el) {
    var paginatonElm = el.querySelector('.swiper-custom-pagination');
    var nextElm = el.querySelector('.swiper-next-btn');
    var prevElm = el.querySelector('.swiper-prev-btn');

    var config = {
        loop: false,
        spaceBetween: 24,
        pagination: {
            el: paginatonElm,
            dynamicBullets: false,
            clickable: true
        },
        navigation: {
            nextEl: nextElm,
            prevEl: prevElm
        },
        breakpoints: {
            [MEDIA_BREAKPOINTS.SMALL]: {
                slidesPerView: swiperConfig.slidesPerViewSmall,
                spaceBetween: 16,
                autoHeight: swiperConfig.autoHeightMobile,
                pagination: {
                    enabled: swiperConfig.indicatorInSmall
                }
            },
            [MEDIA_BREAKPOINTS.MEDIUM]: {
                slidesPerView: swiperConfig.slidesPerViewMedium,
                slidesPerGroup: swiperConfig.slidesPerViewMedium,
                spaceBetween: 16,
                pagination: {
                    enabled: swiperConfig.indicatorInMedium
                }
            },
            [MEDIA_BREAKPOINTS.LARGE]: {
                slidesPerView: swiperConfig.slidesPerViewLarge,
                slidesPerGroup: swiperConfig.slidesPerViewLarge,
                spaceBetween: 16,
                pagination: {
                    enabled: swiperConfig.indicatorInLarge
                }
            }
        },
        on: {
            slideChange: function (swiper) {
                var heroBannerSlides = swiper.slidesEl && $(swiper.slidesEl).find('.swiper-slide .hero-banner');
                if (swiper.slides.length && heroBannerSlides.length) {
                    $(swiper.slides).each(function (index, slide) {
                        var youtubePlayer = $(slide).find('.hero-banner .image-wrapper iframe').get(0);
                        var videoPlayer = $(slide).find('.hero-banner .image-wrapper video').get(0);
                        var playButton = $(slide).find('.hero-banner .image-wrapper .hero-carousel-play-btn');
                        // pause the video if it is playing
                        if ($(slide).find('.hero-banner .youtube-iframe-parent').length && (playButton.length && playButton.data('play') === true)) {
                            playButton.data('play', false);
                            playButton.find('.play-btn-text').removeClass('d-none').addClass('d-flex');
                            playButton.find('.pause-btn-text').removeClass('d-flex').addClass('d-none');
                            playButton.closest('.image-wrapper').find('.youtube-iframe-parent').addClass('d-none');
                            if (youtubePlayer) {
                                youtubePlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                            } else if (videoPlayer) {
                                videoPlayer.pause();
                            }
                        }
                    });
                }
            }
        }
    };

    swiperItems.push(new Swiper(el, config));
};

// keep an eye on viewport size changes
breakpoint.addEventListener('change', () => {
    breakpointChecker();
});

// kickstart
breakpointChecker();
