
'use strict';

var formValidation = require('base/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');

/**
 * Retrieves the value of a query parameter from the current page URL.
 * @param {string} param - The name of the query parameter to retrieve.
 * @returns {string|null} The value of the specified query parameter, or null if the parameter is not present in the URL.
 */
function getQparamValue(param) {
    var pageUrlParams = new URLSearchParams(window.location.search);
    var rurlValue;
    // Check if parameter is present in the page URL
    if (pageUrlParams.has(param)) {
        rurlValue = pageUrlParams.get(param);
    }
    return rurlValue;
}

module.exports = {
    login: function () {
        $(document).on('submit', 'form.login', function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);
                        window.location.href = data.redirectUrl;
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });
    },

    register: function () {
        $(document).on('submit', 'form.registration', function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        $('form.registration').trigger('login:register:error', data);
                        formValidation(form, data);
                    } else {
                        $('form.registration').trigger('login:register:success', data);
                        window.location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        $(document).on('submit', 'form.reset-password-form', function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn').empty()
                                .html('<a href="'
                                        + data.returnUrl
                                        + '" class="btn btn-primary btn-block">'
                                        + data.buttonText + '</a>');
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },

    toggleCreateAccountLink: function () {
        $('body').on('click', '#toggleCreateAccountLink', function (e) {
            e.preventDefault();
            $('#loginFormContainer').addClass('d-none');
            $('#registerFormContainer').removeClass('d-none');
        });
    },
    toggleSignInLink: function () {
        $('body').on('click', '#toggleSignInLink', function (e) {
            e.preventDefault();
            $('#loginFormContainer').removeClass('d-none');
            $('#registerFormContainer').addClass('d-none');
        });
    },

    homePageLoginShow: function () {
        var popupVal = getQparamValue('page');
        if (popupVal && popupVal === 'login') {
            var paramVal = getQparamValue('rurl');
            if (paramVal) {
            // data-url manipulation
                var dataUrl = $('.js-login-modal').attr('data-url');
                var url = new URL(dataUrl);
                var urlParams = new URLSearchParams(url.search);

                // Check if 'rurl' parameter is not present in the data attribute
                if (!urlParams.has('rurl')) {
                    // Update the data attribute of the anchor link
                    urlParams.append('rurl', paramVal);
                    url.search = urlParams.toString();
                    $('.js-login-modal').attr('data-url', url.toString());
                }
            }
            $('.js-login-modal').trigger('click');
        } else if (popupVal && popupVal === 'resetPW') {
            var resetParamVal = getQparamValue('token');
            if (resetParamVal) {
            // data-url manipulation
                var resetDataUrl = $('.js-resetPW-modal').attr('data-url');
                var reseturl = new URL(resetDataUrl);
                var urlResetParams = new URLSearchParams(reseturl.search);

                // Check if 'rurl' parameter is not present in the data attribute
                if (!urlResetParams.has('token')) {
                    // Update the data attribute of the anchor link
                    urlResetParams.append('token', resetParamVal);
                    reseturl.search = urlResetParams.toString();
                    $('.js-resetPW-modal').attr('data-url', reseturl.toString());
                }
            }
            $('.js-resetPW-modal').trigger('click');
        } else if (popupVal && popupVal === 'forgotPW') {
            $('.js-forgotPW-modal').trigger('click');
        }
    },

    changePasswordOnSave: function () {
        $(document).on('submit', 'form.change-password-form-modal', function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.change-password-form-modal').trigger('reset:changePassword', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        window.location.href = data.redirectUrl;
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    }
};
