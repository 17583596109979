'use strict';

module.exports = {
    gtmEventTriggers: function () {
        $('body').on('product:afterAddToCart', (e, data) => {
            if (data && window.dataLayer) {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push(data.addedItemInCart);
            }
        });

        $('body').on('cart:update', (e, data) => {
            if (data && window.dataLayer && data.removedItemInCart) {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push(data.removedItemInCart);
            }
            return data;
        });

        $('body').on('login:success', (e, data) => {
            if (data && window.dataLayer) {
                window.dataLayer.push(data.dataLayerObjLogin);
            }
        });

        $('body').on('login:register:success', (e, data) => {
            if (data && window.dataLayer) {
                window.dataLayer.push(data.dataLayerObjRegister);
            }
        });

        $('body').on('search:wishlist:addproduct', (e, data) => {
            if (data && data.addedItemInWishlist && window.dataLayer) {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push(data.addedItemInWishlist);
            }
        });

        $('body').on('checkout:updateCheckoutView', (e, data) => {
            if (data && window.dataLayer && data.order && !data.error) {
                if (data.order.gtmData) {
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push(data.order.gtmData);
                }
            }
        });

        $('body').on('product:afterAttributeSelect', function (e, response) {
            if (response && response.data && response.data.dataLayerObj && window.dataLayer ) {
                window.dataLayer.push(response.data.dataLayerObj);
            }
        });
    }
};
