'use strict';

var timeoutId = null;
var experianObserverConfig = {
    attributes: true,
    childList: true,
    subtree: true,
    characterData: true
};

var experianMutationObserverCallback = function (mutationsList, observer) {
    for (var mutation of mutationsList) {
        if (mutation.target && mutation.type === 'attributes') {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(function () {
                var $form = $(mutation.target).closest('form');
                $form.find('input, select').each(function () {
                    $(this).removeClass('is-invalid bg--error-light');
                    if (!this.validity.valid) {
                        $(this).trigger('invalid', this.validity);
                    }
                });
                // Disconnect the observer when the job is done
                observer.disconnect();
                $form.data('experianObserverAttached', false); // Reset the flag
            }, 0);
        }
    }
};

var experianObserver = new MutationObserver(experianMutationObserverCallback);

module.exports = function () {
    // event to validate form field when experian suggestion gets selected
    $('body').on('input', '.experian-input-observer', function () {
        var $form = $(this).closest('form');
        var experianObserverAttached = $form.data('experianObserverAttached');

        if (!experianObserverAttached) {
            experianObserver.observe(this, experianObserverConfig);
            $form.data('experianObserverAttached', true); // Set the flag to true after attaching the observer
        }
    });
};
